import React from 'react';
import { Table, Space, Button, Pagination } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { formatCurrencyVND } from '../utils/utils';

const MemberTable = ({ flattenedData, onAddMemberClick, calculationType }) => {
  const [pageSize, setPageSize] = React.useState(10); // Số bản ghi mặc định
  const [currentPage, setCurrentPage] = React.useState(1); // Trang hiện tại

  // Tính toán số lượng bản ghi hiển thị
  const totalRecords = flattenedData.length; // Tổng số bản ghi
  const currentData = flattenedData.slice((currentPage - 1) * pageSize, currentPage * pageSize); // Dữ liệu hiện tại

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <span style={{ marginLeft: `${record.level * 20}px` }}>{text.split(" ")[0]}</span>,
    },
    {
      title: 'Doanh số (VNĐ)',
      dataIndex: 'sales',
      key: 'sales',
      render: (value) => value?.toLocaleString() || '0',
    },
    {
      title: 'Điểm PV',
      dataIndex: 'pv',
      key: 'pv',
      render: (value) => value?.toLocaleString() || '0',
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission',
      render: (text) => formatCurrencyVND(text),
    },
    {
      title: '#',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => onAddMemberClick(record.key)}
          >
            Thêm
          </Button>
        </Space>
      ),
    },
  ];

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={currentData} // Sử dụng dữ liệu hiện tại
        pagination={false} // Tắt phân trang tự động của bảng
        rowKey="key"
        scroll={{ x: 'max-content' }}
      />
      
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalRecords}
          onChange={handlePageChange}
          showSizeChanger // Cho phép thay đổi kích thước trang
        />
        <div>
          Hiện thị {pageSize} trên {totalRecords} bản ghi
        </div>
      </div>
    </div>
  );
};

export default MemberTable;
