// commissionCalculator.js

const commissionRates = [
  { minAmount: 29000000, maxAmount: Infinity, rate: 0.21 },    // X > 29,000,000
  { minAmount: 20000000, maxAmount: 29000000, rate: 0.20 },    // 20,000,000 < X <= 29,000,000
  { minAmount: 13000000, maxAmount: 20000000, rate: 0.18 },    // 13,000,000 < X <= 20,000,000
  { minAmount: 8000000, maxAmount: 13000000, rate: 0.16 },     // 8,000,000 < X <= 13,000,000
  { minAmount: 3500000, maxAmount: 8000000, rate: 0.14 },      // 3,500,000 < X <= 8,000,000
  { minAmount: 1500000, maxAmount: 3500000, rate: 0.12 },      // 1,500,000 < X <= 3,500,000
  { minAmount: 0, maxAmount: 1500000, rate: 0.10 },            // X <= 1,500,000
];


// Hàm lấy % chiết khấu dựa trên số tiền
const getCommissionRate = (amount) => {
  for (const tier of commissionRates) {
    if (tier.minAmount < amount && amount <= tier.maxAmount) {
      return tier.rate;
    }
  }
  // Trường hợp > 29,000,000
  if (amount > 29000000) {
    return 0.21;
  }
  return 0.10; // Mức thấp nhất theo bảng
};

// Hàm tính hoa hồng cho từng thành viên
const calculateCommissionForMember = (member, f1Children = [], f2Children = []) => {
  console.log('\n========================================');
  console.log(`Tính hoa hồng cho: ${member.title}`);
  console.log(`Số tiền cá nhân: ${member.sales}`);
  console.log('----------------------------------------');

  const amount = member.sales;

  // Log thông tin F1
  console.log('Danh sách F1:');
  f1Children.forEach(f1 => {
    console.log(`- ${f1.title}: Số tiền = ${f1.sales}, Tỷ lệ = ${getCommissionRate(f1.sales) * 100}%`);
  });

  // Log thông tin F2
  console.log('\nDanh sách F2:');
  f2Children.forEach(f2 => {
      console.log(`- ${f2.title}: Số tiền = ${f2.sales}, Tỷ lệ = ${getCommissionRate(f2.sales) * 100}%`);
  });

  // Tính HH1
  let amountF1Not21 = 0;
  f1Children.forEach(child => {
    if (getCommissionRate(child.sales) < 0.21) {
      amountF1Not21 += child.sales;
      console.log(`\nCộng Số tiền của ${child.title} (chưa đạt 21%): +${child.sales}`);
    }
  });

  const totalAmount = amount + amountF1Not21;
  const commissionRate = getCommissionRate(totalAmount);
  const hh1 = totalAmount * commissionRate;

  console.log('\nTính HH1:');
  console.log(`- Tổng Số tiền tính HH1 = ${amount} (cá nhân) + ${amountF1Not21} (F1 chưa 21%) = ${totalAmount}`);
  console.log(`- Tỷ lệ theo tổng Số tiền: ${commissionRate * 100}%`);
  console.log(`- HH1 = ${totalAmount} × ${commissionRate} = ${hh1}`);

  // Tính HH2
  let hh2 = 0;
  console.log('\nTính HH2:');
  if (commissionRate < 0.21) {
    f2Children.forEach(f2 => {
      const f2Rate = getCommissionRate(f2.sales);
      const f2Bonus = f2.sales * 0.03;
      hh2 += f2Bonus;
      console.log(`- Từ ${f2.title}: ${f2.sales} × 3% = ${f2Bonus}`);
    });
  } else {
    console.log('- Không được tính HH2 do đã đạt 21%');
  }

  // Tính HH3
  let hh3 = 0;
  console.log('\nTính HH3 (trừ hoa hồng F1 chưa 21%):');
  f1Children.forEach(f1 => {
    const f1Rate = getCommissionRate(f1.sales);
    if (f1Rate < 0.21) {
      const f1Commission = f1.sales * f1Rate;
      hh3 += f1Commission;
      console.log(`- Trừ HH của ${f1.title}: ${f1.sales} × ${f1Rate} = ${f1Commission}`);
    }
  });

  // Tính HH4
  let hh4 = 0;
  console.log('\nTính HH4 (6% từ F1 đạt 21%):');
  f1Children.forEach(f1 => {
    const f1Rate = getCommissionRate(f1.sales);
    if (f1Rate === 0.21) {
      const f1Commission = f1.sales * 0.21;
      const f1Bonus = f1Commission * 0.06;
      hh4 += f1Bonus;
      console.log(`- Từ ${f1.title}: ${f1.sales} × 21% × 6% = ${f1Bonus}`);
    }
  });

  const totalCommission = hh1 + hh2 - hh3 + hh4;

  console.log('\nKết quả cuối cùng:');
  console.log(`HH1: ${hh1}`);
  console.log(`HH2: ${hh2}`);
  console.log(`HH3: ${hh3}`);
  console.log(`HH4: ${hh4}`);
  console.log(`Tổng hoa hồng = ${hh1} + ${hh2} - ${hh3} + ${hh4} = ${totalCommission}`);
  console.log('========================================\n');

  return {
    hh1,
    hh2,
    hh3,
    hh4,
    totalCommission,
  };
};

// Hàm đệ quy tính hoa hồng cho toàn bộ cây
const calculateCommissionForTree = (treeData) => {
  let totalSystemCommission = 0; // Thêm biến theo dõi tổng hoa hồng

  const calculateRecursive = (member) => {
    // Nếu không có con, đây là node lá
    if (!member.children || member.children.length === 0) {
      const commission = calculateCommissionForMember(member, [], []).totalCommission;
      totalSystemCommission += commission; // Cộng vào tổng
      console.log(`${member.key}: ${commission.toLocaleString()}₫`);
      return commission;
    }

    // Tính hoa hồng cho tất cả con trước
    member.children.forEach(child => {
      child.commission = calculateRecursive(child);
    });

    const f1Children = member.children;
    const f2Children = [];
    f1Children.forEach(f1 => {
      if (f1.children) {
        f1.children.forEach(f2 => f2Children.push(f2));
      }
    });

    const commission = calculateCommissionForMember(member, f1Children, f2Children).totalCommission;
    totalSystemCommission += commission; // Cộng vào tổng
    console.log(`${member.key}: ${commission.toLocaleString()}₫`);
    return commission;
  };

  // Tính từ root
  treeData.forEach(member => {
    member.commission = calculateRecursive(member);
  });

  console.log(`Tổng hoa hồng toàn hệ thống: ${totalSystemCommission.toLocaleString()}₫`);
  return treeData;
};

module.exports = {
  calculateCommissionForTree,
  calculateCommissionForMember,
};

