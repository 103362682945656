import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Button } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';
import MemberTree from './components/MemberTree';
import MemberTable from './components/MemberTable';
import MemberModal from './components/MemberModal';
import { calculateCommissionForTree } from './utils/commissionCalculator';
import { generateRandomMembers } from './utils/utils'; // Import hàm random
import SystemOverviewCard from './components/SystemOverviewCard';


const { Header, Content } = Layout;
const PRODUCT_NAME = "Bữa ăn lành mạnh"; 
const PRODUCT_PRICE = 595000; 
const PRODUCT_PV = 205;
const PRODUCT_IMAGE_URL = "https://lotilife.com/wp-content/uploads/2024/08/IMG_2152-1-300x300.jpg";

const INITIAL_TREE_DATA = [
  {
    key: 'Admin',
    title: 'Admin',
    sales: 0,
    pv: 0,
    commission: 0,
    children: [],
  },
];

const ProductInfo = () => {
  return (
      <div style={{ display: 'flex', alignItems: 'center', margin: '20px' }}>
          <img 
              src={PRODUCT_IMAGE_URL} 
              alt="Product" 
              style={{ width: '100px', height: 'auto', marginRight: '20px' }} 
          />
          <div>
              <h3>{PRODUCT_NAME}</h3>
              <h4>Giá: {PRODUCT_PRICE.toLocaleString()} VNĐ</h4>
              <p>Điểm PV: {PRODUCT_PV}</p>
          </div>
      </div>
  );
};

const App = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newMemberCount, setNewMemberCount] = useState(1); // Số lượng thành viên muốn thêm
  const [selectedOption, setSelectedOption] = useState("randomSales"); // Tùy chọn mặc định
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [salesAmount, setSalesAmount] = useState(0);
  const [treeData, setTreeData] = useState([
    {
      key: 'Admin',
      title: 'Admin (615 PV)',
      sales: 1785000,
      pv: 615,
      commission: 0,
      children: [
        {
          key: 'F1-1',
          title: 'F1-1 (615 PV)',
          pv: 615,
          sales: 1785000,
          commission: 0,
          children: [
            {
              key: 'F2-1',
              title: 'F2-1 (615 PV)', 
              pv: 615,
              sales: 1785000,
              commission: 0,
              children: [
                {
                  key: 'F3-1',
                  title: 'F3-1 (615 PV)',
                  pv: 615,
                  sales: 1785000,
                  commission: 0,
                  children: [
                    {
                      key: 'F4-1',
                      title: 'F4-1 (615 PV)',
                      pv: 615,
                      sales: 1785000,
                      commission: 0,
                      children: [
                        {
                          key: 'F5-1',
                          title: 'F5-1 (615 PV)',
                          pv: 615,
                          sales: 1785000,
                          commission: 0
                        },
                        {
                          key: 'F5-2',
                          title: 'F5-2 (615 PV)',
                          pv: 615,
                          sales: 1785000,
                          commission: 0
                        },
                        {
                          key: 'F5-3',
                          title: 'F5-3 (615 PV)',
                          pv: 615,
                          sales: 1785000,
                          commission: 0
                        }
                      ]
                    },
                    {
                      key: 'F4-2',
                      title: 'F4-2 (615 PV)',
                      pv: 615,
                      sales: 1785000,
                      commission: 0,
                      children: [
                        {
                          key: 'F5-4',
                          title: 'F5-4 (615 PV)',
                          pv: 615,
                          sales: 1785000,
                          commission: 0
                        },
                        {
                          key: 'F5-5',
                          title: 'F5-5 (615 PV)',
                          pv: 615,
                          sales: 1785000,
                          commission: 0
                        },
                        {
                          key: 'F5-6',
                          title: 'F5-6 (615 PV)',
                          pv: 615,
                          sales: 1785000,
                          commission: 0
                        }
                      ]
                    },
                    {
                      key: 'F4-3',
                      title: 'F4-3 (615 PV)',
                      pv: 615,
                      sales: 1785000,
                      commission: 0,
                      children: [
                        {
                          key: 'F5-7',
                          title: 'F5-7 (615 PV)',
                          pv: 615,
                          sales: 1785000,
                          commission: 0
                        },
                        {
                          key: 'F5-8',
                          title: 'F5-8 (615 PV)',
                          pv: 615,
                          sales: 1785000,
                          commission: 0
                        },
                        {
                          key: 'F5-9',
                          title: 'F5-9 (615 PV)',
                          pv: 615,
                          sales: 1785000,
                          commission: 0
                        }
                      ]
                    }
                  ]
                },
                {
                  key: 'F3-2',
                  title: 'F3-2 (615 PV)',
                  pv: 615,
                  sales: 1785000,
                  commission: 0,
                  children: []
                },
                {
                  key: 'F3-3',
                  title: 'F3-3 (615 PV)',
                  pv: 615,
                  sales: 1785000,
                  commission: 0,
                  children: []
                }
              ]
            },
            {
              key: 'F2-2',
              title: 'F2-2 (615 PV)',
              pv: 615,
              sales: 1785000,
              commission: 0,
              children: []
            },
            {
              key: 'F2-3',
              title: 'F2-3 (2460 PV)',
              pv: 2460,
              sales: 1785000,
              commission: 0,
              children: []
            }
          ]
        },
        {
          key: 'F1-2',
          title: 'F1-2 (615 PV)',
          pv: 615,
          sales: 1785000,
          commission: 0,
          children: []
        },
        {
          key: 'F1-3',
          title: 'F1-3 (615 PV)',
          pv: 615,
          sales: 1785000,
          commission: 0,
          children: []
        }
      ],
    },
  ]);

  const [flattenedData, setFlattenedData] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  
  const calculateMemberCounts = (node) => {
    const level1Count = node.children ? node.children.length : 0;
    let level2Count = 0;

    if (node.children) {
      node.children.forEach((child) => {
        if (child.children) {
          level2Count += child.children.length;
        }
      });
    }

    return { level1Count, level2Count };
  };

  const calculateSystemOverview = (nodes) => {
    let members = 0;
    let sales = 0;
    let commission = 0;

    const traverseTree = (nodes) => {
      nodes.forEach((node) => {
        // Duyệt con trước
        if (node.children && node.children.length > 0) {
          traverseTree(node.children);
        }
        
        members += 1;
        sales += node.sales || 0;
        if (node.commission) {
          console.log(`${node.key} commission: ${node.commission.toLocaleString()}₫`);
          commission += node.commission;
        }
      });
    };

    traverseTree(nodes);
    console.log(`Total members: ${members}`);
    console.log(`Total sales: ${sales.toLocaleString()}₫`);
    console.log(`Total commission: ${commission.toLocaleString()}₫`);
    
    setTotalMembers(members);
    setTotalSales(sales);
    setTotalCommission(commission);
  };

  const updateTreeDataWithCounts = (data) => {
    return data.map((node) => {
      const { level1Count, level2Count } = calculateMemberCounts(node);
      let updatedTitle = node.title.split(" ")[0];

      if (level1Count > 0 || level2Count > 0) {
        updatedTitle += ` (${level1Count} Lv1, ${level2Count} Lv2)`;
      }

      const updatedNode = {
        ...node,
        title: updatedTitle, // Cập nhật tiêu đề mới
        children: node.children ? updateTreeDataWithCounts(node.children) : [],
      };
      return updatedNode;
    });
  };

  const flattenTree = (nodes, parent = null, level = 0) => {
    return nodes.reduce((acc, node) => {
      const { children, ...nodeWithoutChildren } = node;
      const flatNode = {
        ...nodeWithoutChildren,
        parent: parent ? parent.title : null,
        level,
      };
      acc.push(flatNode);
      if (children) {
        acc.push(...flattenTree(children, node, level + 1));
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    const updatedTree = updateTreeDataWithCounts([...treeData]);
    setFlattenedData(flattenTree(updatedTree));
    calculateSystemOverview(updatedTree);
  }, []);

  useEffect(() => {
    const updatedTree = updateTreeDataWithCounts([...treeData]);
    setTreeData(updatedTree);
  }, []);

  const handleCalculateCommission = () => {
    const updatedTreeData = calculateCommissionForTree(treeData);
    setTreeData([...updatedTreeData]);
    
    // Cập nhật lại tổng quan hệ thống
    calculateSystemOverview(updatedTreeData);
    
    // Cập nhật lại flattenedData
    const updatedTreeWithCounts = updateTreeDataWithCounts([...updatedTreeData]);
    setFlattenedData(flattenTree(updatedTreeWithCounts));
  };

  const handleAddNewMembers = (count) => {
    const newMembers = generateRandomMembers(newMemberCount, PRODUCT_PRICE, PRODUCT_PV, selectedOption, salesAmount);
  
    const addMembersToTree = (data, key) => {
      return data.map((item) => {
        if (item.key === key) {
          return { ...item, children: [...(item.children || []), ...newMembers] };
        }
        if (item.children) {
          return { ...item, children: addMembersToTree(item.children, key) };
        }
        return item;
      });
    };
  
    const updatedTree = addMembersToTree(treeData, selectedNodeKey);
    setTreeData(updatedTree);
    
    // Cập nhật flattenedData
    setFlattenedData(flattenTree(updatedTree));
    
    // Cập nhật các thông số tổng quan
    calculateSystemOverview(updatedTree);
  
    // Reset các state
    setIsModalVisible(false);
    setNewMemberCount(1);
    setSelectedOption("randomSales");
    setSalesAmount(0);
  };
  
  const handleAddMemberClick = (key) => {
    setSelectedNodeKey(key);
    setIsModalVisible(true); // Mở modal để nhập số lượng thành viên cần thêm
  };

  const handleReset = () => {
    setTreeData(INITIAL_TREE_DATA);
    setFlattenedData(flattenTree(INITIAL_TREE_DATA));
    setTotalMembers(1);
    setTotalSales(0);
    setTotalCommission(0);
    setSelectedNodeKey(null);
    setIsModalVisible(false);
    setNewMemberCount(1);
    setSelectedOption("randomSales");
    setSalesAmount(0);
  };

  return (
    <Layout>
      <Header style={{ color: 'white', fontSize: '24px', padding: '0 16px', display: 'flex', alignItems: 'center' }}>
        Commission Calculator
      </Header>        
      <Content style={{ padding: '16px' }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={6} style={{ marginBottom: '20px' }}> {/* Cột 1 chiếm 30% */}
            <ProductInfo />
            <SystemOverviewCard 
              totalMembers={totalMembers} 
              totalSales={totalSales}
              totalCommission={totalCommission}
            />
            <Button
              type="primary"
              onClick={handleCalculateCommission}
              style={{ marginTop: '20px', width: '100%' }}
              icon={<CalculatorOutlined />}
            >
              Tính hoa hồng
            </Button>
            <Button
              type="default"
              danger
              onClick={handleReset}
              style={{ marginTop: '10px', width: '100%' }}
            >
              Reset Data
            </Button>
          </Col>
          <Col xs={24} md={12}> {/* Cột 3 chiếm 40% */}
            <MemberTable flattenedData={flattenedData} onAddMemberClick={handleAddMemberClick} />
          </Col>
          <Col xs={24} md={6} style={{ marginBottom: '20px' }}> {/* Cột 2 chiếm 30% */}
            <MemberTree treeData={treeData} setSelectedNodeKey={setSelectedNodeKey} setIsModalVisible={setIsModalVisible} />
          </Col>
        </Row>
        <MemberModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          newMemberCount={newMemberCount}
          setNewMemberCount={setNewMemberCount}
          handleAddNewMembers={handleAddNewMembers}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption} 
          setSalesAmount={setSalesAmount}
        />
      </Content>
    </Layout>
  );
};

export default App;