import { faker } from '@faker-js/faker';

export const formatCurrencyVND = (value) => {
  return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
};

// Hàm random danh sách thành viên mới dựa vào giá sản phẩm và PV
export const generateRandomMembers = (count, productPrice, productPV, option, salesAmount) => {
  const members = [];

  for (let i = 0; i < count; i++) {
    let quantity; // Số lượng sản phẩm
    let sales;
    let pv;

    switch (option) {
      case "random21":
        // Để đạt doanh số >29tr (mức 21%), cần ít nhất 49 sản phẩm
        // Random từ 49-60 sản phẩm
        quantity = Math.floor(Math.random() * (60 - 49) + 49);
        break;

      case "sameSales":
        quantity = salesAmount;
        break;

      case "randomSales":
      default:
        // Random từ 1-60 sản phẩm
        quantity = Math.floor(Math.random() * 60 + 1);
        break;
    }

    // Tính doanh số và PV dựa trên số lượng sản phẩm
    sales = quantity * productPrice;
    pv = quantity * productPV;

    members.push({
      key: `F${Date.now()}-${Math.random().toString(36).substr(2, 6)}`,
      title: `${faker.person.fullName()} (${pv.toLocaleString()} PV)`,
      sales,
      pv,
      commission: 0,
    });
  }
  return members;
};