import React from 'react';
import { Card, Statistic } from 'antd';
import { formatCurrencyVND } from '../utils/utils';

const SystemOverviewCard = ({ totalMembers, totalSales, totalCommission }) => {
  return (
    <Card title="Tổng quan hệ thống">
      <Statistic
        title="Tổng thành viên"
        value={totalMembers}
        suffix="người"
      />
      <Statistic
        title="Tổng doanh số"
        value={totalSales}
        suffix="₫"
      />
      <Statistic
        title="Tổng hoa hồng"
        value={totalCommission}
        suffix="₫"
      />
    </Card>
  );
};

export default SystemOverviewCard;
