import React from 'react';
import { Tree } from 'antd';

const MemberTree = ({ treeData, setSelectedNodeKey, setIsModalVisible }) => {
  const onNodeRightClick = ({ node }) => {
    setSelectedNodeKey(node.key);
    setIsModalVisible(true);
  };

  const renderTreeNodes = (nodes) => {
    return nodes.map(node => ({
      ...node,
      title: (
        <span>
          {node.title.split(" ")[0]}
          {node.commission ? ` (${node.commission.toLocaleString()}₫)` : ''}          
        </span>
      ),
      children: node.children ? renderTreeNodes(node.children) : []
    }));
  };

  return (
    <Tree
      showLine
      treeData={renderTreeNodes(treeData)}
      onRightClick={onNodeRightClick}
      defaultExpandAll
    />
  );
};

export default MemberTree;
