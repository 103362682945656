import React from 'react';
import { Modal, InputNumber, Form, Select } from 'antd';
const { Option } = Select;

const MemberModal = ({ 
  isModalVisible, 
  setIsModalVisible, 
  newMemberCount, 
  setNewMemberCount, 
  handleAddNewMembers, 
  selectedOption, 
  setSelectedOption,
  salesAmount, 
  setSalesAmount 
}) => {
  const handleOk = () => {
    handleAddNewMembers(newMemberCount, selectedOption, salesAmount); // Gọi hàm thêm thành viên ngẫu nhiên với tùy chọn
    setIsModalVisible(false); // Đóng modal
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Đóng modal
  };

  return (
    <Modal
      title="Thêm thành viên"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form layout="vertical">
        <Form.Item label="Số lượng muốn thêm ">
          <InputNumber
            min={1}
            value={newMemberCount}
            onChange={(value) => setNewMemberCount(value)} // Cập nhật số lượng thành viên cần thêm
          />
        </Form.Item>
        <Form.Item label="Select Option">
          <Select value={selectedOption} onChange={setSelectedOption}>
            <Option value="random21">Random đạt 21%</Option>
            <Option value="sameSales">Cùng số lượng bán ra</Option>
            <Option value="randomSales">Random số lượng bán ra</Option>
          </Select>
        </Form.Item>
        {selectedOption === "sameSales" && (
          <Form.Item label="Số lượng bán ra">
            <InputNumber
              min={0}
              value={salesAmount}
              onChange={(value) => setSalesAmount(value)} // Cập nhật số lượng bán ra
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default MemberModal;